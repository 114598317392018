import {
	Box,
	Flex,
	FormControl,
	Input,
	Radio,
	RadioGroup,
	Stack,
	Tag,
} from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import moment from "moment"
import { ComponentProps, FC, useState } from "react"
import ReactSelect from "react-select"
import { SelectOption } from "src/components/shared/ReactSelect"
import { DrawerForm, ErrorMessageField } from "src/components/ui"
import { InputLabel } from "src/components/ui/InputLabel"
import { Profile } from "src/domain/entities/profile"
import { Surname } from "src/domain/entities/surname"
import { Gender, MaritalStatus } from "src/utils/enums"
import { fetchGujaratiSuggestions } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { IProfileBasicUpdateFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	profile: Profile
	handleSubmit: FormikOnSubmit<IProfileBasicUpdateFormFields>
	surnameList: Surname[]
	surnameSearchText: string
	setSurnameSearchText: (value: string) => void
}

export const ProfileBasicUpdateDrawerFormView: FC<Props> = ({
	profile,
	handleSubmit,
	surnameList,
	setSurnameSearchText,
	...rest
}) => {
	const [nameSuggestions, setNameSuggestions] = useState<string[]>([])

	if (!profile) return null

	return (
		<Formik<IProfileBasicUpdateFormFields>
			initialValues={{
				dateOfBirth: profile.dateOfBirth ?? undefined,
				firstName: profile.firstName,
				gender: profile.gender,
				maritalStatus: profile.maritalStatus,
				surnameId: profile.surname?.id,
				isAlive: profile.isAlive,
			}}
			onSubmit={handleSubmit}
			enableReinitialize={true}
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				const handleNameChange = async (
					e: React.ChangeEvent<HTMLInputElement>,
				) => {
					setFieldValue("firstName.en", e.target.value)
					const suggestions = await fetchGujaratiSuggestions(e.target.value)
					setNameSuggestions(suggestions)
				}

				const verifiedSurnameOptions = surnameList
					.filter((surname) => surname.isApproved)
					.map((surname) => ({
						value: surname.id,
						label: `${surname.name.en} (${surname.name.gu}) - ${surname.code}`,
					}))

				const maritalStatusOptions = [
					{ value: MaritalStatus.SINGLE, label: "Single" },
					{ value: MaritalStatus.ENGAGED, label: "Engaged" },
					{ value: MaritalStatus.MARRIED, label: "Married" },
					{ value: MaritalStatus.SEPARATED, label: "Separated" },
					{ value: MaritalStatus.DIVORCED, label: "Divorced" },
					{ value: MaritalStatus.WIDOW, label: "Widow" },
				]

				return (
					<DrawerForm
						size="sm"
						headerLabel="Basic Details Update"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"sm"} marginX={"auto"}>
							<Flex gridGap={2}>
								<Box>
									{/* Name */}
									<FormControl>
										<InputLabel label="Name" />
										<Input
											name="firstName.en"
											placeholder="Name"
											maxLength={50}
											required
											autoFocus
											value={values.firstName?.en}
											onChange={handleNameChange}
										/>
										<ErrorMessage
											component={ErrorMessageField}
											name="firstName.en"
										/>
									</FormControl>
									<Box>
										{nameSuggestions.map((el, i) => (
											<Tag
												colorScheme={"green"}
												backgroundColor={"green.50"}
												variant="outline"
												_hover={{
													backgroundColor: "green.100",
												}}
												cursor="pointer"
												margin={0.5}
												onClick={() => {
													setFieldValue("firstName.gu", el)
													setNameSuggestions([])
												}}
												key={i}
											>
												{el}
											</Tag>
										))}
									</Box>
								</Box>

								{/* Name Gu */}
								<FormControl>
									<InputLabel label="Name Gujarati" />
									<Input
										name="firstName.gu"
										placeholder="Name Gujarati"
										maxLength={50}
										required
										value={values.firstName?.gu}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="firstName.gu"
									/>
								</FormControl>
							</Flex>
							{/* Surname */}
							<FormControl flex={2}>
								<InputLabel label="Surname" />
								<ReactSelect
									name="surnameId"
									onChange={(newValue) => {
										setFieldValue(
											"surnameId",
											(newValue as SelectOption).value,
										)
									}}
									onInputChange={(newValue) => {
										setSurnameSearchText(newValue)
									}}
									value={verifiedSurnameOptions.find(
										(el) => el.value === values.surnameId,
									)}
									options={verifiedSurnameOptions}
									isSearchable
								/>
							</FormControl>
							<Flex gridColumnGap={2}>
								{/* Gender */}
								<FormControl flex={1}>
									<InputLabel label="Gender" />
									<RadioGroup
										name="gender"
										value={values.gender}
										onChange={(val) => {
											setFieldValue("gender", val)
										}}
									>
										<Stack direction="row">
											<Radio value={Gender.MALE}>Male</Radio>
											<Radio value={Gender.FEMALE}>Female</Radio>
										</Stack>
									</RadioGroup>
								</FormControl>
								{/* Date of birth */}
								<FormControl flex={1}>
									<InputLabel label="Date of birth" />
									<Input
										type={"date"}
										name="dateOfBirth"
										value={moment(values.dateOfBirth).format(
											"YYYY-MM-DD",
										)}
										onChange={handleChange}
									/>
								</FormControl>
							</Flex>
							<Flex gridColumnGap={2}>
								{/* Is Alive? */}
								<FormControl flex={1}>
									<InputLabel label="Is Alive?" />
									<RadioGroup
										name="isAlive"
										value={values.isAlive?.toString()}
										onChange={(e) => {
											setFieldValue("isAlive", e === "true")
										}}
									>
										<Stack direction="row">
											<Radio value={true.toString()}>Yes</Radio>
											<Radio value={false.toString()}>No</Radio>
										</Stack>
									</RadioGroup>
								</FormControl>
								{/* Marital Status */}
								<FormControl flex={2}>
									<InputLabel label="Marital Status" />
									<ReactSelect
										name="maritalStatus"
										onChange={(newValue) => {
											setFieldValue(
												"maritalStatus",
												(newValue as SelectOption).value,
											)
										}}
										value={maritalStatusOptions.find(
											(el) => el.value === values.maritalStatus,
										)}
										options={maritalStatusOptions}
									/>
								</FormControl>
							</Flex>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
