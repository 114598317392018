import { usePagination } from "@ajna/pagination"
import {
	Box,
	Flex,
	Input,
	InputGroup,
	InputRightAddon,
	Tag,
	Text,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { BsSearch } from "react-icons/bs"
import ReactSelect from "react-select"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { InputLabel } from "src/components/ui/InputLabel"
import { SubCaste } from "src/domain/entities/subCaste"
import { fetchGujaratiSuggestions } from "src/utils/helpers"

import {
	useAuth,
	useMatrimonyProfileListApi,
	useSubCasteListApi,
} from "../../../domain/hooks"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { MatrimonyProfileListController } from "./MatrimonyProfileList"

import { debounce } from "lodash"
import { matrimonyProfileVerifyApi } from "src/domain/api/matrimonyProfile/matrimonyProfileVerifyApi"
import { MatrimonyProfile } from "src/domain/entities/matrimonyProfile"
import { Gender } from "src/utils/enums"
import { MatrimonyProfileDeleteDialogController } from "./MatrimonyProfileDeleteDialogController"
import { matrimonyProfileUpdateApi } from "src/domain/api/matrimonyProfile/matrimonyProfileUpdateApi"

export const MatrimonyProfilesPage: FC = () => {
	const [isMatrimonyProfileDeleteDialogOpen, setIsMatrimonyProfileDeleteDialogOpen] =
		useState(false)
	const deleteMatrimonyProfileRef = useRef<MatrimonyProfile>()
	const { matrimonyProfileList, isLoading, fetchMatrimonyProfileList } =
		useMatrimonyProfileListApi()
	const { token } = useAuth()

	const { subCasteList, fetchSubCasteList } = useSubCasteListApi()

	const [selectedSubCaste, setSelectedSubCaste] = useState<SubCaste>()
	const [selectedGender, setSelectedGender] = useState<Gender>()
	const [selectedIsVerifiedFilter, setSelectedIsVerifiedFilter] = useState<boolean>()

	const pagination = usePagination({
		initialState: { currentPage: 1, pageSize: 15 },
	})
	const searchText = useRef("")
	const [searchInputText, setSearchInputText] = useState("")
	const [gujaratiSuggestions, setGujaratiSuggestions] = useState<string[]>([])

	const getGujaratiSuggestions = useCallback(async (searchText: string) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		setGujaratiSuggestions(suggestions)
	}, [])

	const fetchMatrimonyProfiles = useCallback(async () => {
		return await fetchMatrimonyProfileList({
			fetch: {
				profile: {
					surname: {},
				},
				featuredImage: true,
			},
			search: searchText.current,
			gender: selectedGender,
			subCasteId: selectedSubCaste?.id,
			isVerified: selectedIsVerifiedFilter,
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
		})
	}, [
		fetchMatrimonyProfileList,
		pagination.currentPage,
		selectedGender,
		searchText,
		selectedSubCaste?.id,
		selectedIsVerifiedFilter,
	])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedFetchMatrimonyProfiles = useCallback(
		debounce(async () => {
			await fetchMatrimonyProfiles()
		}, 500),
		[fetchMatrimonyProfiles],
	)

	const handleMatrimonyProfileVerificationChange = useCallback(
		async (val: boolean, matrimonyProfile: MatrimonyProfile) => {
			if (!token) return
			await matrimonyProfileVerifyApi(
				{
					matrimonyProfileId: matrimonyProfile.id,
					isVerified: val,
				},
				token,
			)

			await fetchMatrimonyProfiles()
		},
		[token, fetchMatrimonyProfiles],
	)

	const handleMatrimonyProfileActiveChange = useCallback(
		async (val: boolean, matrimonyProfile: MatrimonyProfile) => {
			if (!token) return
			await matrimonyProfileUpdateApi(
				{
					id: matrimonyProfile.id,
					update: { isActive: val },
				},
				token,
			)

			await fetchMatrimonyProfiles()
		},
		[token, fetchMatrimonyProfiles],
	)

	useEffect(() => {
		fetchMatrimonyProfiles()
	}, [fetchMatrimonyProfiles])

	useEffect(() => {
		fetchSubCasteList()
	}, [fetchSubCasteList])

	const subCasteOptions: {
		label: string
		value?: SubCaste
	}[] = subCasteList.map((subCaste) => ({
		label: subCaste.name.en,
		value: subCaste,
	}))

	subCasteOptions.unshift({
		label: "All",
		value: undefined,
	})

	const genderOptions: {
		label: string
		value?: Gender
	}[] = Object.values(Gender).map((gender) => ({
		label: gender.toUpperCase(),
		value: gender,
	}))

	genderOptions.unshift({
		label: "All",
		value: undefined,
	})

	const isVerifiedOptions: {
		label: string
		value?: boolean
	}[] = [
		{
			label: "All",
			value: undefined,
		},
		{
			label: "Verified",
			value: true,
		},
		{
			label: "Un Verified",
			value: false,
		},
	]

	return (
		<DashboardWrapper>
			<Box padding={2} display={{ base: "none", lg: "contents" }}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Matrimony Profiles
					</Text>
				</Flex>
			</Box>
			<Box px={2} mb={4} mt={{ base: "2", lg: "0" }}>
				<Flex
					gridColumnGap={2}
					align="flex-end"
					direction={{ base: "column", lg: "row" }}
				>
					<Box width={{ base: "full", lg: "fit-content" }}>
						<InputGroup>
							<Input
								value={searchInputText}
								onChange={(e) => {
									pagination.setCurrentPage(1)
									searchText.current = e.target.value
									setSearchInputText(e.target.value)
									debouncedFetchMatrimonyProfiles()
									getGujaratiSuggestions(e.target.value)
								}}
								type="text"
								placeholder="Search"
							/>
							<InputRightAddon>
								<BsSearch />
							</InputRightAddon>
						</InputGroup>
						{gujaratiSuggestions.map((el, i) => (
							<Tag
								key={i}
								colorScheme={"green"}
								backgroundColor={"green.50"}
								variant="outline"
								_hover={{
									backgroundColor: "green.100",
								}}
								cursor="pointer"
								margin={0.5}
								onClick={() => {
									pagination.setCurrentPage(1)
									searchText.current = el
									setSearchInputText(el)
									debouncedFetchMatrimonyProfiles()
									setGujaratiSuggestions([])
								}}
							>
								{el}
							</Tag>
						))}
					</Box>
					{/* Sub Caste */}
					<Box width={{ base: "full", lg: "15%" }} my={{ base: "2", lg: "0" }}>
						<InputLabel label="Sub Caste" />
						<ReactSelect
							name="subCasteId"
							onChange={(val) => {
								pagination.setCurrentPage(1)
								setSelectedSubCaste(val?.value)
							}}
							value={subCasteOptions.find(
								(el) => el.value?.id === selectedSubCaste?.id,
							)}
							options={subCasteOptions}
						/>
					</Box>
					<Box width={{ base: "100%", lg: "15%" }} mt={{ base: "2", lg: "0" }}>
						<InputLabel label="Gender" />
						<ReactSelect
							name="gender"
							onChange={(val) => {
								pagination.setCurrentPage(1)
								setSelectedGender(val?.value)
							}}
							value={genderOptions.find(
								(el) => el.value === selectedGender,
							)}
							options={genderOptions}
						/>
					</Box>
					<Box width={{ base: "100%", lg: "15%" }} mt={{ base: "2", lg: "0" }}>
						<InputLabel label="Verification" />
						<ReactSelect
							name="gender"
							onChange={(val) => {
								pagination.setCurrentPage(1)
								setSelectedIsVerifiedFilter(val?.value)
							}}
							value={isVerifiedOptions.find(
								(el) => el.value === selectedIsVerifiedFilter,
							)}
							options={isVerifiedOptions}
						/>
					</Box>
				</Flex>
			</Box>
			<MatrimonyProfileListController
				list={matrimonyProfileList}
				handleMatrimonyProfileVerificationChange={
					handleMatrimonyProfileVerificationChange
				}
				handleMatrimonyProfileActiveChange={handleMatrimonyProfileActiveChange}
				isLoading={isLoading}
				onDelete={(matrimonyProfile) => {
					deleteMatrimonyProfileRef.current = matrimonyProfile
					setIsMatrimonyProfileDeleteDialogOpen(true)
				}}
			/>
			<CustomPagination
				pagination={pagination}
				isNextDisabled={matrimonyProfileList.length === 0}
			/>
			{deleteMatrimonyProfileRef.current && isMatrimonyProfileDeleteDialogOpen ? (
				<MatrimonyProfileDeleteDialogController
					isOpen={isMatrimonyProfileDeleteDialogOpen}
					setIsOpen={setIsMatrimonyProfileDeleteDialogOpen}
					matrimonyProfile={deleteMatrimonyProfileRef.current}
					onSuccess={() => fetchMatrimonyProfiles()}
				/>
			) : null}
		</DashboardWrapper>
	)
}
