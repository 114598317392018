import {
	Avatar,
	Box,
	Card,
	CardBody,
	CardHeader,
	Flex,
	Heading,
	Image,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	Stack,
	StackDivider,
	Tag,
	TagLabel,
	Text,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useState } from "react"
import { AiOutlineLoading3Quarters } from "react-icons/ai"
import { BsPatchCheckFill, BsPerson } from "react-icons/bs"
import { ImageViewModalController } from "src/components/ui/ImageViewModalController"
import { useBusinessListApi } from "src/domain/hooks"
import { getVillageName } from "src/utils/helpers"

interface Props {
	businessId: string
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	onProfileClick: (profileId: string) => void
	handleProfileClick: (profileId: string) => void
}

export const BusinessDetailsModal: FC<Props> = ({
	isOpen,
	setIsOpen,
	onProfileClick,
	businessId,
}) => {
	const { fetchBusinessList, isLoading, businessList } = useBusinessListApi()

	const [selectedViewImageUrl, setSelectedViewImageUrl] = useState<string | null>(null)

	const fetchBusinessDetails = useCallback(async () => {
		try {
			await fetchBusinessList({
				id: businessId,
				fetch: {
					currentVillage: {
						talukaDistrictStateCountry: true,
					},
					category: {},
					members: {
						surname: {},
					},
					identityProofImage: true,
					createdBy: {
						profile: true,
					},
					logoImage: true,
					allImages: true,
				},
			})
		} catch (err) {
			console.error(err)
		}
	}, [fetchBusinessList, businessId])

	useEffect(() => {
		fetchBusinessDetails()
	}, [fetchBusinessDetails])

	const [businessDetails] = businessList

	if (!businessDetails) return null

	return (
		<>
			<Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
				<ModalOverlay />
				<ModalContent rounded={"2xl"} overflow="hidden">
					<ModalBody p="0" bgColor={"gray.100"}>
						<Box>
							<Box
								p={{ base: "3", lg: "8" }}
								pb={{ base: "0", lg: "2" }}
								backgroundColor={"#CEC9F3"}
							>
								<Flex>
									<Box position={"relative"}>
										<Flex
											borderRadius={"full"}
											overflow="hidden"
											w="100px"
											h="100px"
											flex={"none"}
											bgColor="gray.100"
											align={"center"}
											justify={"center"}
										>
											{isLoading ? (
												<AiOutlineLoading3Quarters />
											) : businessDetails?.logoImageUrl ? (
												<Image
													src={businessDetails?.logoImageUrl}
													alt="Business Logo"
													objectFit="cover"
													onClick={() =>
														setSelectedViewImageUrl(
															businessDetails.logoImageUrl!,
														)
													}
												/>
											) : (
												<BsPerson size={20} />
											)}
										</Flex>
									</Box>
									<Box flex={1} ml="6">
										<Box
											color={"purple.800"}
											fontSize="2xl"
											lineHeight={"1.3"}
											fontWeight="bold"
										>
											<Text as={"span"}>
												{businessDetails.name.en}
											</Text>
											{businessDetails?.isVerified ? (
												<Text
													display={"inline-block"}
													ml={2}
													color="blue.400"
												>
													<BsPatchCheckFill size={16} />
												</Text>
											) : null}
										</Box>
										<Flex direction={"column"} rowGap="2" mt="2">
											<Box
												color={"purple.800"}
												fontSize="sm"
												textTransform={"capitalize"}
											>
												{businessDetails?.category?.name.en}
											</Box>
											{businessDetails?.currentVillage ? (
												<Flex>
													{/* <Box mt={1} ml={-6}>
														<BsGeo size={14} />
													</Box> */}
													<Box
														color={"purple.800"}
														fontSize="sm"
														textTransform={"capitalize"}
													>
														{getVillageName(
															businessDetails.currentVillage,
														)}
													</Box>
												</Flex>
											) : null}
											<Box>
												{businessDetails.mobileNumber1 &&
												businessDetails.mobileNumber2 ? (
													<Flex
														fontSize={"sm"}
														color={"purple.800"}
													>
														<Box mr={1}>
															{
																businessDetails.mobileNumber1
															}
														</Box>
														{"/"}
														<Box ml={1}>
															{
																businessDetails.mobileNumber2
															}
														</Box>
													</Flex>
												) : (
													(
														<Box>
															{
																businessDetails.mobileNumber1
															}
														</Box>
													) ?? "-"
												)}
											</Box>
										</Flex>
									</Box>
								</Flex>
							</Box>
						</Box>
						{/* About */}
						<Card
							mx={3}
							mt={3}
							bgColor={"##F8F7FD"}
							boxShadow={"0px 2px 8px 2px  rgba(115, 140, 133, 0.15)"}
						>
							<CardHeader>
								<Heading size="sm" color={"purple.500"}>
									ABOUT
								</Heading>
							</CardHeader>

							<CardBody mt={-6}>
								<Stack divider={<StackDivider />} spacing="2">
									<Flex align={"center"}>
										<Heading size="xs" color={"##64748B"}>
											Business Type
										</Heading>
										<Text fontSize="sm" color={"#56606e"} ml={"80px"}>
											{businessDetails.type}
										</Text>
									</Flex>
									<Flex align={"center"}>
										<Heading size="xs" color={"##64748B"}>
											Registration Type
										</Heading>
										<Text fontSize="sm" color={"#56606e"} ml={"58px"}>
											{businessDetails?.registrationType?.toUpperCase()}
										</Text>
									</Flex>
									<Flex align={"center"}>
										<Heading size="xs" color={"##64748B"}>
											Business Category
										</Heading>
										<Text fontSize="sm" color={"#56606e"} ml={"50px"}>
											{businessDetails.category?.name.en}
										</Text>
									</Flex>
									<Flex align={"center"}>
										<Heading size="xs" color={"##64748B"}>
											Total Members
										</Heading>
										<Text fontSize="sm" color={"#56606e"} ml={"80px"}>
											{businessDetails.totalMembers}
										</Text>
									</Flex>
									<Flex align={"center"}>
										<Heading size="xs" color={"##64748B"}>
											Total Products
										</Heading>
										<Text fontSize="sm" color={"#56606e"} ml={"80px"}>
											{businessDetails.totalProducts}
										</Text>
									</Flex>
									<Flex align={"center"}>
										<Heading size="xs" color={"##64748B"}>
											Total Photos
										</Heading>
										<Text fontSize="sm" color={"#56606e"} ml={"80px"}>
											{businessDetails.imageUrls.length}
										</Text>
									</Flex>
								</Stack>
							</CardBody>
						</Card>

						{/* Basic */}
						<Card
							mx={3}
							mt={3}
							bgColor={"##F8F7FD"}
							boxShadow={"0px 2px 8px 2px  rgba(115, 140, 133, 0.15)"}
						>
							<CardHeader>
								<Heading size="sm" color={"purple.500"}>
									BASIC
								</Heading>
							</CardHeader>

							<CardBody mt={-6}>
								<Stack divider={<StackDivider />} spacing="2">
									<Flex align={"center"}>
										<Heading size="xs" color={"##64748B"}>
											Address
										</Heading>
										<Text fontSize="sm" color={"#56606e"} ml={"80px"}>
											{businessDetails.address}
										</Text>
									</Flex>
									<Flex align={"center"}>
										<Heading size="xs" color={"##64748B"}>
											Mobile No.
										</Heading>
										<Box fontSize="sm" color={"#56606e"} ml={"58px"}>
											{businessDetails.mobileNumber1 &&
											businessDetails.mobileNumber2 ? (
												<Flex
													fontSize={"sm"}
													color={"#56606e"}
													ml={1}
												>
													<Box mr={1}>
														{businessDetails.mobileNumber1}
													</Box>
													{"/"}
													<Box ml={1}>
														{businessDetails.mobileNumber2}
													</Box>
												</Flex>
											) : (
												(
													<Box>
														{businessDetails.mobileNumber1}
													</Box>
												) ?? "-"
											)}
										</Box>
									</Flex>
									<Flex align={"center"}>
										<Heading size="xs" color={"##64748B"}>
											Email
										</Heading>
										<Text fontSize="sm" color={"#56606e"} ml={"98px"}>
											{businessDetails.email}
										</Text>
									</Flex>
									<Flex align={"center"}>
										<Heading size="xs" color={"##64748B"}>
											Website
										</Heading>
										<Text fontSize="sm" color={"#56606e"} ml={"80px"}>
											{businessDetails.website}
										</Text>
									</Flex>
									<Flex align={"center"}>
										<Heading size="xs" color={"##64748B"}>
											Total Products
										</Heading>
										<Text fontSize="sm" color={"#56606e"} ml={"36px"}>
											{businessDetails.totalProducts}
										</Text>
									</Flex>
								</Stack>
							</CardBody>
						</Card>
						<Card
							mx={3}
							mt={3}
							bgColor={"##F8F7FD"}
							boxShadow={"0px 2px 8px 2px  rgba(115, 140, 133, 0.15)"}
						>
							<CardHeader px={"20px"} py={"10px"}>
								<Heading size="sm" color={"purple.500"}>
									CREATED BY
								</Heading>
							</CardHeader>
							<CardBody px={5} py={2}>
								<Flex align={"center"}>
									<Avatar
										size={"sm"}
										mr={"8px"}
										border={"1px"}
										borderColor={"#d5d5d5"}
										name={businessDetails.createdBy?.id}
										src={
											businessDetails?.createdBy?.profile
												?.profileImageUrl
										}
									/>
									<Flex mr={2} fontWeight={"normal"}>
										<Text>
											{businessDetails?.createdBy?.profile?.surname
												?.name.en ?? ""}
										</Text>
										<Text ml={2}>
											{businessDetails?.createdBy?.profile
												?.firstName.en ?? ""}
										</Text>
										<Tag
											size="md"
											ml={2}
											colorScheme="purple"
											borderRadius="full"
											onClick={(e) => {
												e.stopPropagation()
											}}
										>
											<TagLabel>Owner</TagLabel>
										</Tag>
									</Flex>
								</Flex>
							</CardBody>
						</Card>
						<Card
							mx={3}
							mt={3}
							bgColor={"##F8F7FD"}
							boxShadow={"0px 2px 8px 2px  rgba(115, 140, 133, 0.15)"}
						>
							<CardHeader px={"20px"} py={"10px"}>
								<Heading size="sm" color={"purple.500"}>
									MEMBERS
								</Heading>
							</CardHeader>
							<CardBody px={5} py={2}>
								<Box>
									{businessDetails.members.map((member, i) => {
										return (
											<Box
												mt={2}
												onClick={
													member?.profile
														? () =>
																onProfileClick(
																	member.profile!.id,
																)
														: () => {}
												}
											>
												<Flex mb={4}>
													{member.profile.profileImageUrl ? (
														<Avatar
															key={i}
															size={"xs"}
															mr={"8px"}
															border={"1px"}
															borderColor={"#d5d5d5"}
															name={
																businessDetails.createdBy
																	?.id
															}
															src={
																member?.profile
																	?.profileImageUrl
															}
														/>
													) : (
														<Flex
															height={26}
															width={26}
															bgColor="gray.200"
															rounded={"full"}
															justify="center"
															align={"center"}
															color="purple.900"
															border={"1px"}
															borderColor={"purple.200"}
															mr={2}
														>
															<BsPerson
																width={"14px"}
																height={"14px"}
															/>
														</Flex>
													)}

													<Box mr={2}>
														{member?.profile?.firstName.en ??
															""}{" "}
														{member?.profile?.surname?.name
															.en ?? ""}
													</Box>
													<Tag
														size="md"
														colorScheme="purple"
														borderRadius="full"
														onClick={(e) => {
															e.stopPropagation()
														}}
													>
														<TagLabel>
															{member.designation.en}
														</TagLabel>
													</Tag>
												</Flex>
											</Box>
										)
									})}
								</Box>
							</CardBody>
						</Card>
						<Box my={3}>
							{isLoading ? (
								<AiOutlineLoading3Quarters />
							) : (
								<Box rounded={"lg"}>
									<Image
										src={businessDetails?.identityProofImageUrl}
										alt="Business Identity Proof Image"
										rounded={"2xl"}
										objectFit="cover"
										w={"full"}
										px={4}
										h={"200px"}
										onClick={() =>
											setSelectedViewImageUrl(
												businessDetails.identityProofImageUrl!,
											)
										}
									/>
								</Box>
							)}
						</Box>
					</ModalBody>
				</ModalContent>
			</Modal>
			{selectedViewImageUrl != null ? (
				<ImageViewModalController
					isOpen={selectedViewImageUrl != null}
					setIsOpen={() => setSelectedViewImageUrl(null)}
					image={
						<Image
							src={selectedViewImageUrl}
							alt="profile"
							objectFit="contain"
							maxH="90vh"
							maxW="90vw"
						/>
					}
				/>
			) : null}
		</>
	)
}
