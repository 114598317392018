export enum Language {
	ENGLISH = "en",
	GUJARATI = "gu",
}

export enum Gender {
	MALE = "male",
	FEMALE = "female",
}

export enum MaritalStatus {
	SINGLE = "single",
	MARRIED = "married",
	ENGAGED = "engaged",
	SEPARATED = "separated",
	DIVORCED = "divorced",
	WIDOW = "widow",
}

export function getMaritalStatusName(status: MaritalStatus) {
	switch (status) {
		case MaritalStatus.SINGLE:
			return "Single"
		case MaritalStatus.MARRIED:
			return "Married"
		case MaritalStatus.ENGAGED:
			return "Engaged"
		case MaritalStatus.SEPARATED:
			return "Separated"
		case MaritalStatus.DIVORCED:
			return "Divorced"
		case MaritalStatus.WIDOW:
			return "Widow"
	}
}

export enum ClientType {
	BROWSER = "browser",
	ANDROID = "android",
	IOS = "ios",
	SKALPS = "skalps",
}

export enum AuthRole {
	ADMIN = "admin",
	USER = "user",
	REGISTRATION = "user_registration",
	REVIEWER = "user_reviewer",
	SKALPS = "user_skalps",
	SKALPS_ADMIN = "skalps_admin",
}

export enum RegistrationStep {
	BASIC = "basic",
	LOCATION = "location",
	IDENTITY = "identity",
}

export enum ReviewStatus {
	PENDING = "pending",
	IN_REVIEW = "in_review",
	REJECTED = "rejected",
	APPROVED = "approved",
}

export enum AuthMethod {
	withApple = "withApple",
	withGoogle = "withGoogle",
	withMobile = "withMobile",
}

export enum OccupationType {
	SELF_EMPLOYED = "self_employed",
	SALARIED = "salaried",
}

export enum AnnualIncomeClass {
	LESS_5_LAC = "less_5_lac",
	BET_5_AND_20_LAC = "bet_5_and_20_lac",
	BET_20_AND_50_LAC = "bet_20_and_50_lac",
	MORE_50_LAC = "more_50_lac",
}

export enum BloodGroup {
	A_POSITIVE = "a_positive",
	A_NEGATIVE = "a_negative",
	B_POSITIVE = "b_positive",
	B_NEGATIVE = "b_negative",
	AB_POSITIVE = "ab_positive",
	AB_NEGATIVE = "ab_negative",
	O_POSITIVE = "o_positive",
	O_NEGATIVE = "o_negative",
}
export function getBloodGroupType(status: BloodGroup) {
	switch (status) {
		case BloodGroup.A_POSITIVE:
			return "A +ve"
		case BloodGroup.A_NEGATIVE:
			return "A -ve"
		case BloodGroup.B_POSITIVE:
			return "B +ve"
		case BloodGroup.B_NEGATIVE:
			return "B -ve"
		case BloodGroup.AB_POSITIVE:
			return "AB +ve"
		case BloodGroup.AB_NEGATIVE:
			return "AB -ve"
		case BloodGroup.O_POSITIVE:
			return "O +ve"
		case BloodGroup.O_NEGATIVE:
			return "O -ve"
	}
}

export enum MatrimonyProfilePropertyType {
	HOUSE = "house",
	PLOT = "plot",
	AGRICULTURAL_LAND = "agricultural_land",
	SHOP = "shop",
	FACTORY = "factory",
	COMMERCIAL_SPACE = "commercial_space",
}

export function getMatrimonyProfilePropertyType(status: MatrimonyProfilePropertyType) {
	switch (status) {
		case MatrimonyProfilePropertyType.AGRICULTURAL_LAND:
			return "Agricultural Land"
		case MatrimonyProfilePropertyType.HOUSE:
			return "House"
		case MatrimonyProfilePropertyType.SHOP:
			return "Shop"
		case MatrimonyProfilePropertyType.FACTORY:
			return "Factory"
		case MatrimonyProfilePropertyType.COMMERCIAL_SPACE:
			return "Commercial Space"
		case MatrimonyProfilePropertyType.PLOT:
			return "Plot"
	}
}

export enum FeedbackType {
	GENERAL = "general",
	FEATURE_REQUEST = "feature_request",
	ISSUE = "issue",
	QUERY = "query",
}

export enum ContactCategory {
	GENERAL = "general",
	SOCIAL = "social",
}

export enum MobileAppBuildStatus {
	TESTING = "testing",
	LIVE = "live",
	DEPRECATED = "deprecated",
	ABANDONED = "abandoned",
}

export enum BusinessType {
	PRODUCT = "product",
	SERVICE = "service",
	BOTH = "both",
}

export function getBusinessType(status: BusinessType) {
	switch (status) {
		case BusinessType.PRODUCT:
			return "Product"
		case BusinessType.SERVICE:
			return "Service"
		case BusinessType.BOTH:
			return "Both"
	}
}

export enum BusinessRegistrationType {
	UNREGISTERED = "unregistered",
	ONE_PERSON_COMPANY = "one_person_company",
	SOLE_PROPRIETORSHIP_FIRM = "sole_proprietorship_firm",
	PARTNERSHIP_FIRM = "partnership_firm",
	LIMITED_LIABILITY_PARTNERSHIP_FIRM = "limited_liability_partnership_firm",
	PRIVATE_LIMITED_COMPANY = "private_limited_company",
	PUBLIC_LIMITED_COMPANY = "public_limited_company",
}

export function getBusinessRegistrationType(status: BusinessRegistrationType) {
	switch (status) {
		case BusinessRegistrationType.UNREGISTERED:
			return "Unregistered"
		case BusinessRegistrationType.ONE_PERSON_COMPANY:
			return "One Person Company"
		case BusinessRegistrationType.SOLE_PROPRIETORSHIP_FIRM:
			return "Sole Proprietorship Firm"
		case BusinessRegistrationType.PARTNERSHIP_FIRM:
			return "Partnership Firm"
		case BusinessRegistrationType.LIMITED_LIABILITY_PARTNERSHIP_FIRM:
			return "Limited Liability Partnership Firm"
		case BusinessRegistrationType.PRIVATE_LIMITED_COMPANY:
			return "Private Limited Company"
		case BusinessRegistrationType.PUBLIC_LIMITED_COMPANY:
			return "Public Limited Company"
	}
}
